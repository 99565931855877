import React from "react";
import { useDispatch } from "react-redux";
import { changeQuantity, removeItem } from "../../../../redux/actions/MarketPlaceActions";
import circle from '../../../../assets/img/circulogris.png';
import lessCIrcle from '../../../../assets/img/lessCIrcle.png';
import plusCircle from '../../../../assets/img/plusCircle.png';
import trash from '../../../../assets/img/trash.png';
import { formatPrice } from "../../../../utils/functions";
import './ProductItem.css'


const ProductItem = ({
  item,
  onClick = () => { }
}) => {
  const dispatch = useDispatch()
  return (
    <div key={item._id} className="content-product-bag-container">
      <div className="content-product-bag">
      <div className="content-img-card-list-p" onClick={onClick}>
        <img alt="img" className="img-content-card-list-pr" onError={(e) => e.target.src = circle} src={item.image ? item.image.url.origin : circle}></img>
      </div>
      <div className="content-name-product-bag" onClick={onClick}>
        <div className="content-text-product-bag">
          <div className="title-product-bag">
            {item.name}
          </div>
          <div className="decription-product-bag">
            {item.description}
          </div>
          <div className="title-product-bag">
            {`${formatPrice(item.price)} x und`}
          </div>
        </div>
      </div>
      <div className="content-options-product-bag" onClick={onClick}>

        <div className="content-options-btns-product-bag">
          <img onClick={() => {
            if (item.quantity - 1 > 0) {
              dispatch(changeQuantity(item, item.quantity - 1))
            }

          }} src={lessCIrcle} height={20} alt="" />
          <div className="quantity-container-product-bag">
          <div className="quantity-title-product-bag">Cantidad</div>
            <div className="quantity-product-bag">{item.quantity}</div>
           
          </div>
          <img onClick={() => {
            dispatch(changeQuantity(item, item.quantity + 1))
          }} src={plusCircle} height={20} alt="" />
        </div>
        <div className="total-container-product-bag">
          <div className="total-title-product-bag">Total</div>
          <div className="total-product-bag">
            {formatPrice(item.price * item.quantity)}
          </div>
        </div>
      </div>
     

      </div>
      <div className="trash-product-bag">
        <img onClick={() => {
          dispatch(removeItem(item))
        }} src={trash} height={20} alt="" />
      </div>
    </div>
  );
};

export default ProductItem;
