import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Section from '../Section/Section'
import logo from '../../assets/img/icons/icon-logo.png'
import logotipo from './../../assets/img/N-Identidad.png'
import imgAbout from '../../assets/img/img-about.png'
import './style.css'
import { Button } from '../Button/Button'
export const OurHistory = () => {
  return (
    <div className='history-container'>
       <h1 className='history-title'>Historia</h1>
      
           
            <div className='history-container-info'>
                <div className='history-container-info-content'>
                      <p>
                      Estamos clasificados como usuarios industriales y de servicios de la Zona Franca Permanente del Cauca, 
                      con el objetivo de cuidar el planeta a través de la producción y comercialización de productos de 
                      higiene biodegradables para el cuidado personal y de los hogares.<br/>
                        <br/>          

                        A principios de 2022, terminamos la construcción de nuestra planta de productos de higiene para el hogar, 
                        certificada con capacidad de producción por el INVIMA el 14 de marzo de 2022. Posteriormente, se adicionó la planta de productos de higiene personal y cosmética, 
                        certificada con capacidad de producción por el INVIMA el 28 de febrero de 2023.<br/>
                          
                        <br/> 
                        El compromiso ambiental de Ecoideas incluye sus actividades como gestora ambiental para el almacenamiento, tratamiento, gestión y aprovechamiento de residuos (ACUS), 
                        autorizada por la Corporación Autónoma Regional del Cauca (CRC), la Corporación Autónoma Regional del Valle (CVC) y el Departamento Administrativo de Gestión del Medio Ambiente (DAGMA).<br/>
                            
                        <br/>
                        En cumplimiento de su misión, en octubre de 2023, Ecoideas reforma sus estatutos para la adopción de la condición de empresa B, decisión que determinó modificaciones significativas al objeto social, 
                        respecto a las obligaciones de las directivas, la implementación de herramientas de gobernanza legal y el fortalecimiento de los compromisos ambientales.<br/>
                            <br/>
                            En Ecoideas, cada paso es parte del camino en busca del objetivo de ser agentes del cambio social y ambiental.
                    </p>
                </div>
                
                <div className='history-container-info-content'>
                    <img src={logotipo} className={'style-logotipo-h'} />
                </div>      

            </div> 
          
        <img className={'img-About'} src={imgAbout}/>
    </div>
  )
}
