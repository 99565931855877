import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Section from '../Section/Section'
import imgAbout from '../../assets/img/img-about.png'
import './style.css'




export const OurIdentity = () => {
  return (
    <div className='Ouridentity-container'> 
        <h1 className= 'Identidad-title'> Identidad</h1>

            <div className='Ouridentity-content'>           
                              
               
            <h3 className="mt-3"  style={{margin:"24px"}}>Misión</h3>
                    <p>
                    Trabajamos comprometidos con la protección del ambiente, la producción consciente y limpia, la sostenibilidad, y el comercio justo y colaborativo, buscando el bienestar y la confianza de la comunidad, el entorno, los colaboradores, los empleados, los socios, proveedores y clientes. 
                    ECOIDEAS tiene como fin contribuir a la transformación social y al desarrollo del país a través de procesos de producción ambientalmente conscientes, fabricando productos biodegradables que se distinguen por su calidad y pertinencia, 
                    con la responsabilidad de proteger los recursos naturales y el planeta, promoviendo complementariamente el desarrollo de proyectos de transformación y aprovechamiento de residuos, fundamentada en sus valores empresariales y soportada en el uso de las TIC.
                    </p>
            
                
            </div>

            <div className='Ouridentity-content'>
                
                <div className={'icons-history'}>
                                      
                </div>                    
        
                <h3 className="mt-3"  style={{margin:"24px"}} >Visión</h3>
                    <p>
                    Para el 2025, ECOIDEAS será reconocida en el ámbito nacional e internacional como una empresa innovadora, global, sostenible, ambiental y socialmente responsable, que brinda más y mejores alternativas ecológicas en sus procesos, productos, servicios, canales y sistemas, y por su compromiso con el medio ambiente, la economía colaborativa, la inclusión, la calidad, la pertinencia y el desarrollo de proyectos con impacto social, ambiental y empresarial. Una empresa que persigue la excelencia en sus productos biodegradables de precio justo y procura la democratización de la protección del ambiente.
                    </p>
               
            </div>
            <h1 className= 'Identidad-title'>Compromisos</h1>
         

            <div className='Ouridentity-content'>
                <h3 className="mt-3" style={{margin:"24px"}}> Empresa B</h3>
                  <p>
                  Somos una Empresa B, creciendo con propósito. Nos enfocamos en ser sostenibles, transformando positivamente nuestro entorno y las vidas de todos aquellos que hacen parte de Ecoideas. Perseguimos la transparencia en cada acto y medimos los impactos sociales y ambientales que causamos. Estamos comprometidos con la mejora continua y teniendo en el centro de nuestro modelo de negocio el propósito socioambiental. Medimos y analizamos las cinco áreas más relevantes de nuestra empresa: Gobierno, Colaboradores, Clientes, Comunidad y Medio Ambiente para ser agentes del cambio en la economía, protegiendo la misión y potenciando el triple impacto.
                  </p>
                  { /* <img src={imgEMPRESAB}  style={{margin:"24px"}} className="img-fluid" alt="EMPRESA B" /> */}
              
            </div>
        
            <div className='Ouridentity-content'>
                <h3 className="mt-3" style={{margin:"24px"}} >Gestores Ambientales</h3>
                  <p>
                  En ECOIDEA STORES S.A.S. BIC, estamos autorizados para la gestión ambiental eficiente y responsable de aceites de cocina usados (ACUS o AVUS). Somos Gestores Ambientales para su recolección y transporte por la CVC según la resolución No. 0701-466432022 del 14 de julio de 2022 y por el DAGMA según la resolución No. 202341330100161091 del 29 de septiembre de 2023. En colaboración con ECOIDEA FOR YOU S.A.S. BIC, nuestra empresa asociada, garantizamos la disposición final, tratamiento y aprovechamiento de estos aceites, con la autorización de la CRC mediante la resolución No. SDP-20014-2022 del 5 de noviembre de 2022 y del DAGMA con la resolución No. 202341330100161181 del 5 de septiembre de 2023. Trabajamos para asegurar prácticas sostenibles y responsables en la gestión de residuos, contribuyendo a la protección del medio ambiente.
                  </p>
                  { /* <img src={imgAcces} style={{margin:"24px"}} className="img-fluid" alt="Gestores Ambientales" /> */ }
            </div>


            <div className='Ouridentity-content'>
                <h3 className="mt-3" style={{margin:"24px"}}>Empresa BIC</h3>
                <p>Somos una Sociedad de Beneficio e Interés Colectivo (BIC). Combinamos las ventajas de nuestra actividad comercial y económica con acciones concretas para procurar el bienestar de los colaboradores, aportar a la equidad social del país y contribuir a la protección del medio ambiente. Nuestro modelo se sustenta en cinco dimensiones de la sostenibilidad: Modelo de Negocio, Gobierno Corporativo, Prácticas Laborales, Prácticas Ambientales y Prácticas con la Comunidad.
                </p>
                { /* <img src={imgAcompa} style={{margin:"24px"}}  className="img-fluid" alt="AEmpresa BIC" />  */ }
            </div>
             
        

            <div className='Ouridentity-sub-content'>

              <h5 className="mt-3" style={{margin:"16px"}}>Modelo de Negocio</h5>
                <p>Actuamos velando por el desarrollo y bienestar de nuestros proveedores, implementando con ellos prácticas de comercio justo, compras locales o inclusión productiva de mujeres.</p>
              
              <h5 className="mt-3" style={{margin:"16px"}}>Gobierno Corporativo</h5>
                <p>Ampliamos la participación de mujeres, trabajadores, personas discapacitadas o grupos étnicos en las asambleas, juntas y equipos directivos, así como promovemos una cultura de la ética y la transparencia en la divulgación de sus impactos.</p>
              
              <h5 className="mt-3" style={{margin:"16px"}}>Prácticas Laborales</h5>
                <p>Buscamos el desarrollo y el bienestar de nuestros colaboradores, generando espacios para la educación y capacitación, flexibilidad en la jornada laboral, actuando con equidad salarial y procurando todo tipo de beneficios, incluyendo la posibilidad de participación accionaria.</p>
              
              <h5 className="mt-3" style={{margin:"16px"}}>Prácticas Ambientales</h5>
                <p>Actuamos para mitigar los impactos de la actividad productiva sobre el medio ambiente y promovemos su protección. Ejecutamos auditorías y mediciones, programas de reciclaje y/o aprovechamiento de residuos, incorporación de energías limpias, prácticas de eficiencia energética, entre otras.</p>
              
              <h5 className="mt-3" style={{margin:"16px"}}>Prácticas con la Comunidad</h5>
                <p>Contribuimos a la solución de problemas de la comunidad, creando opciones de trabajo para la población estructuralmente desempleada (jóvenes en situación de riesgo, individuos sin hogar, reinsertados o personas que han salido de la cárcel), voluntariado y obras sociales.</p>
            </div>

           

            <img className={'img-About'} src={imgAbout}/>

        
    </div>
  )
}

