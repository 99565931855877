import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { getDisscount, cleartDisscount, seeplacesPickup, getEcopointsRedeem, sendProductsToPay } from '../../../redux/actions/MarketPlaceActions';
import { createPaymentlink } from '../../../redux/actions/PasarelaActions';
import marketplaceBag from '../../../assets/img//MarketplaceBag.png'
import useMercadoPago from '../../../utils/hooks/useMercadoPago';
import PaymentSummary from '../../../containers/ShopingBag/PaymentSummary';
import ProductItem from './ProductItem/ProductItem';
import './ShopingBag.css'
import TitleImg from '../../../utils/Components/TitleImg/TitleImg';
import localStorage from '../../../utils/hooks/useLocalStorage';
import Policies from '../../Policies/Shipping_info';

const ShopingBag = () => {
    const dispatch = useDispatch()
    const { disscounts, placesPickup, pointsToRedeem, isLoadingPointsToRedeem,bag } = useSelector(state => state.marketPlace)
    const [typeOfDelivery, setTypeOfDelivery] = useState("home")
    const [typeOfPay, setTypeOfPay] = useState("")
    const [pointsRedem, setPointsRedem] = useState(0)

    const [deliveryAddress, setDeliveryAddress] = useState("")
    const [deliveryEntityId, setDeliveryEntityId] = useState("")
    const [type, setType] = useState("user")
    const [country, setCountry] = useState("")
    const [location, setLocation] = useState("")
    const [state, setState] = useState("")
    const [city, setCity] = useState("")
    const [phone, setPhone] = useState("")

    useEffect(() => {
        // dispatch(getLimitReports())
        dispatch(seeplacesPickup())
        dispatch(getEcopointsRedeem())
    }, [])
 
    const history = useNavigate()
    const mp = useMercadoPago()

    const disscount = 0.2;
    const typeUser = "ecotienda"
    const calculateSubTotal = (bag) => {
        let total = 0
        bag.map(product => {
            total = total + (product.quantity * product.unit_price)
        })
        return total
    }
    const calculateTotal = (value) => {

        return (value * (disscounts.length > 0 ? disscounts.map(item => item.value / 100).reduce((prev, next) => prev * next) : 0))

    }
    const pay = () => {
        dispatch(sendProductsToPay(deliveryAddress,history,country,city,location,state,phone))
    }
    return (
        <div className='container-page scroll-menu'>
            <TitleImg img={marketplaceBag} height={60} titleHighlight={"EcoBolsa"} />
            <div className='shoping-bag-container'>
                <div className='list-products-bag scroll-menu'>
                    {bag.map(item => (
                        <ProductItem item={item} />
                    ))}
                </div>
                <PaymentSummary
                    isLoadingPointsToRedeem={isLoadingPointsToRedeem}
                    wareHausesWinery={placesPickup}
                    typeOfDelivery={typeOfDelivery} setTypeOfDelivery={setTypeOfDelivery}
                    subTotal={calculateSubTotal(bag)}
                    total={calculateTotal(calculateSubTotal(bag))}
                    pay={pay}
                    type={type}
                    typeUser={disscounts.length > 0 ? disscounts.map(item => item.to) : ''}
                    disscount={disscounts.length > 0 ? disscounts.map(item => item.value / 100).reduce((prev, next) => prev * next) : 0} {...{
                        typeOfPay, setTypeOfPay, pointsToRedeem, pointsRedem, setPointsRedem, deliveryAddress, setDeliveryAddress
                        , deliveryEntityId, setDeliveryEntityId,country,setCountry,state,setState,city,setCity,location,setLocation,phone, setPhone
                    }} />  
            </div>
            <Policies/>
        </div>
    )
};

export default ShopingBag;
